<template>
  <div>
    <section class="m-1">
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Event">
            <v-select
              multiple
              :reduce="(event) => event.event_id"
              v-model="filters.event_ids"
              placeholder="Select Event"
              label="event_name"
              value="event_id"
              :options="eventListData"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="2">
          <b-form-group label="Booking Code">
            <b-form-input
              v-model="filters.booking_code"
              placeholder="booking code"
            ></b-form-input> </b-form-group
        ></b-col>

        <b-col cols="12" md="3">
          <b-form-group label="Customer Email">
            <b-form-input
              v-model="filters.customer_email"
              placeholder="email"
            ></b-form-input> </b-form-group
        ></b-col>

        <b-col cols="12" md="3">
          <b-form-group label="Search"
            ><b-form-input
              v-model="filters.search"
              placeholder="search name/phone..."
            ></b-form-input> </b-form-group
        ></b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Show">
            <b-form-radio-group
              id="radio-group-1"
              v-model="filters.selectedEventType"
              :options="optionsEventTypes"
              name="radio-options"
            ></b-form-radio-group> </b-form-group
        ></b-col>

        <b-col cols="12" md="3">
          <b-form-group label="Payment & Booking Status">
            <v-select
              :clearable="false"
              :reduce="(st) => st.id"
              v-model="filters.paymentBookStatus"
              placeholder="Select Status"
              label="value"
              value="id"
              :options="statusList"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="12" md="5"
          ><b-button
            @click="getReservationList"
            class="align-button mr-1"
            variant="primary"
            >Search</b-button
          ><b-button
            @click="resetFilters"
            class="align-button mr-1"
            variant="outline-danger"
            >Reset</b-button
          >
          <b-button
            @click="onExportReport"
            class="align-button"
            variant="outline-success"
            >Export Data</b-button
          >
        </b-col>
      </b-row>
    </section>
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
          hover
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>

          <template #cell(payment_capture)="data">
            <b-button
              size="sm"
              v-b-modal.modal-response
              @click="formatPaymentResponse(data.item.payment_capture)"
              v-if="data.item.payment_capture"
              variant="link"
              >show payment response</b-button
            >
            <div v-else class="text-nowrap">payment not initiated</div>
          </template>

          <template #cell(booking_date_time)="data">
            {{
              moment(data.item.booking_date_time).format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            }}
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <b-modal id="bv-modal-eventinfo" hide-footer>
      <template #modal-title> Scan Actions </template>
      <div>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            @change="onScanChange"
            id="radio-group-1"
            v-model="selectedScan"
            :options="optionsScan"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-input
          :v-model="
            selectedScan == '0'
              ? remove_scan_ticket_count
              : add_scan_ticket_count
          "
          :id="`type-remove`"
          min="0"
          max="5"
          type="number"
        ></b-form-input>
      </div>
      <b-button variant="primary" class="mt-3" block @click="onScanSubmit"
        >Submit</b-button
      >
    </b-modal>
    <div>
      <b-modal
        ok-only
        size="lg"
        id="modal-response"
        title="Payment Gateway Response"
      >
        <h4>
          <b style="color: red"> *Do not share payment gateway response.</b>
        </h4>
        <b-card>
          <b-card-text>
            <div>{{ paymentResponseDataString }}</div>
          </b-card-text>
        </b-card>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  GetReservationListService,
  GetEventListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";

export default {
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      moment: moment,
      searchValue: null,
      filters: {
        event_ids: [],
        booking_code: null,
        customer_email: null,
        promo_code: null,
        search: null,
        selectedEventType: "Y",
        paymentBookStatus: "Y",
      },

      statusList: [
        {
          id: "Y",
          value: "All",
        },
        {
          id: "N",
          value: "Refund Transaction",
        },
      ],
      isBusy: false,
      selectedScanTransaction: null,
      fields: [
        {
          key: "sr",
          label: "SR",
        },

        {
          key: "event_name",
          label: "Event Name",
          sortable: true,
        },
        {
          key: "reservation_id",
          label: "ReservationID",
          sortable: true,
        },
        {
          key: "c_name",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Customer Email",
          sortable: true,
        },
        {
          key: "phone_number",
          label: "Customer Phone",
          sortable: true,
        },
        {
          key: "country_code",
          label: "Phone Country code",
          sortable: true,
        },
        {
          key: "is_guest",
          label: "is guest",
          sortable: true,
        },
        {
          key: "seatName",
          label: "Seat Names",
          sortable: true,
        },
        {
          key: "seatTypes",
          label: "Seat Types",
          sortable: true,
        },
        {
          key: "seatPrice",
          label: "Total Seat Price",
          sortable: true,
        },
        {
          key: "seatPrice",
          label: "Total Seat Price",
          sortable: true,
        },
        {
          key: "is_reserved",
          label: "Booking Status",
          sortable: true,
        },
        {
          key: "is_paid",
          label: "Payment Status",
          sortable: true,
        },

        {
          key: "is_refund",
          label: "Is Refund Case",
          sortable: true,
        },
        {
          key: "payment_capture",
          label: "Payment Gateway Response",
          sortable: true,
        },
        {
          key: "totalPaidAmount",
          label: "Total Paid Amount",
          sortable: true,
        },
        {
          key: "amountCurrency",
          label: "currency",
          sortable: true,
        },
        {
          key: "booking_code",
          label: "Booking Code",
          sortable: true,
        },
        {
          key: "booking_date_time",
          label: "booking date time",
          sortable: true,
        },
      ],
      items: [],
      eventListData: [],
      paymentResponseDataString: null,
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
      selectedScan: "0",
      add_scan_ticket_count: 0,
      remove_scan_ticket_count: 0,
      optionsScan: [
        { text: "Remove from Scan Tickets", value: "0" },
        { text: "Add to Scan Tickets", value: "1" },
      ],
      selectedEventType: "Y",
      optionsEventTypes: [
        { text: "Active Events", value: "Y" },
        { text: "Disabled Events", value: "N" },
        { text: "All Events", value: "" },
      ],
    };
  },
  beforeMount() {
    this.getEventList();
    this.getReservationList();
  },
  computed: {},
  components: {
    vSelect,
  },
  methods: {
    async onExportReport() {
      let url = null;
      let excelName = "";

      let payload = JSON.stringify(this.filters);

      const anchorElement = document.createElement("a");
      document.body.appendChild(anchorElement);
      anchorElement.style.display = "none";

      url = this.BASE_URL + `/admin/exportReservationReport?payload=${payload}`;

      fetch(url, {
        headers: {
          "content-type": "application/Json",
          authorization: sessionStorage.getItem("access_token"),
          "x-access-token": sessionStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          excelName =
            "Reservation Report-" + moment().format("DD/MM/YYYY") + ".xlsx";

          //console.log(blob);
          var url = window.URL.createObjectURL(blob);
          anchorElement.href = url;
          anchorElement.download = excelName;
          anchorElement.click();

          window.URL.revokeObjectURL(url);
          // window.open(_url, "_blank").focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async resetFilters() {
      this.filters = {
        event_ids: [],
        booking_code: null,
        customer_email: null,
        promo_code: null,
        search: null,
        selectedEventType: "Y",
      };
      this.getReservationList();
    },
    async formatPaymentResponse(value) {
      if (value) {
        let str = value;
        const mapObj = {
          tktfox: "",
          api: "",
          https: "",
          confirmTapPayment: "",
          reservation_id: "",
          event_token: "",
          merchant: "",
          25662311: "",
          tap_id: "",
          queryData: "",
        };

        str = str.replace(
          /\b(?:tktfox|api|https|confirmTapPayment|reservation_id|event_token|merchant|25662311|tap_id|queryData)\b/gi,
          (matched) => mapObj[matched]
        );
        this.paymentResponseDataString = str;
      } else {
        this.paymentResponseDataString = "";
      }
      // console.log(str);
    },
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getReservationList();
      }, 1000);
    },

    async getReservationList() {
      try {
        this.isBusy = true;
        const response = await GetReservationListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          filters: this.filters,
        });
        if (response.data.status) {
          this.items = response.data.Records.data;
          if (!isNaN(response.data.Records.pagination.total)) {
            this.pagination.totalRows = response.data.Records.pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    onScanSubmit() {},
    onScanChange() {
      this.add_scan_ticket_count = 0;
      this.remove_scan_ticket_count = 0;
    },
    onScanActions(selectedItem) {
      this.selectedScanTransaction = selectedItem;
      this.$bvModal.show("bv-modal-eventinfo");
    },

    onChange() {
      this.$nextTick(() => {
        this.getReservationList();
      });
    },
    async getEventList() {
      this.eventListData = [];
      try {
        const response = await GetEventListService({
          limit: 5000,
          // country_id: this.form.country_id,
        });
        if (response.data.status) {
          this.eventListData = response.data.Records;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
