<template>
  <div>
    <!-- <div>
      <b
        ><h1>
          Page Under Development (Below is the dummy data for representation)
        </h1>
      </b>
    </div>
    <hr /> -->

    <div>
      <b-row>
        <b-col md="12">
          <b-button-group>
            <b-button
              @click="getEventList(11, 'Y')"
              :variant="
                selectedEventType == 'Y' ? 'primary' : 'outline-primary'
              "
              >Active Events</b-button
            >
            <b-button
              @click="getEventList(11, '')"
              :variant="!selectedEventType ? 'primary' : 'outline-primary'"
              >All Events</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
      <b-card class="mt-2" no-body>
        <b-card-text>
          <b-alert variant="success" show>
            <div class="text-center">
              <b-button size="sm" variant="link">
                {{ selectedEventType == "Y" ? "Total Active" : "All" }} Events
                <b-badge variant="primary">{{
                  itemsEventArray.length
                }}</b-badge>
              </b-button>
            </div></b-alert
          >
          <b-row>
            <b-col cols="12" md="12">
              <div
                class="m-1"
                style="
                  border: 1px solid grey;
                  border-radius: 5px;
                  margin-right: 1 px;
                "
              >
                <div class="accordion" role="tablist">
                  <b-card
                    v-for="(itemEvent, indexEvent) in itemsEventArray"
                    :key="indexEvent"
                    no-body
                    class="mb-1"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        @click="getEventDataById(itemEvent.event_id)"
                        block
                        size="sm"
                        :variant="
                          selectedEventId == itemEvent.event_id
                            ? 'dark'
                            : 'secondary'
                        "
                        ><b
                          >{{ itemEvent.event_name }}<br /><br />

                          Date :
                          {{
                            moment(itemEvent.event_start_date).format(
                              "DD/MM/YYYY"
                            )
                          }}
                          -
                          {{
                            moment(itemEvent.event_end_date).format(
                              "DD/MM/YYYY"
                            )
                          }}</b
                        >
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="`accordion-` + itemEvent.event_id"
                      :visible="
                        selectedEventId == itemEvent.event_id ? true : false
                      "
                      :accordion="`accordion-` + itemEvent.event_id"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <b-list-group>
                            <b-list-group-item
                              ><b
                                >Total Transaction :
                                {{
                                  eventData && eventData.event_total_trans
                                    ? eventData.event_total_trans
                                    : 0
                                }}
                              </b></b-list-group-item
                            >

                            <b-list-group-item
                              ><b
                                >Voucher Transaction :
                                {{
                                  eventData &&
                                  eventData.event_total_voucher_trans
                                    ? eventData.event_total_voucher_trans
                                    : 0
                                }}
                              </b>
                              <table
                                v-if="
                                  eventData &&
                                  eventData.voucherSummaryArray &&
                                  eventData.voucherSummaryArray.length
                                "
                                class="mt-1"
                                style="width: 50%"
                              >
                                <tr>
                                  <td>Voucher Code</td>
                                  <td>Seat Type</td>
                                  <td>Count</td>
                                </tr>
                                <tr
                                  v-for="(
                                    voc, indexVoc
                                  ) in eventData.voucherSummaryArray"
                                  :key="indexVoc"
                                >
                                  <td>{{ voc.VoucherCode }}</td>
                                  <td>{{ voc.SeatType }}</td>
                                  <td>{{ voc.Count }}</td>
                                </tr>
                              </table>
                            </b-list-group-item>

                            <b-list-group-item
                              ><b
                                >Total Seats Booked :
                                {{
                                  eventData && eventData.event_total_bookedSeats
                                    ? eventData.event_total_bookedSeats
                                    : 0
                                }}
                              </b></b-list-group-item
                            >

                            <b-list-group-item
                              ><b
                                >Total Seats Scanned :
                                {{
                                  eventData &&
                                  eventData.event_total_seats_scanned
                                    ? eventData.event_total_seats_scanned
                                    : 0
                                }}
                              </b></b-list-group-item
                            >

                            <div class="mt-1">
                              <h5>
                                <b><u>Schedule Details</u></b>
                              </h5>
                              <b-list-group
                                v-if="
                                  eventData &&
                                  eventData.eventScheduleSummary &&
                                  eventData.eventScheduleSummary.length
                                "
                                horizontal="md"
                              >
                                <b-list-group-item
                                  variant="secondary"
                                  v-for="(
                                    itemType, indexType
                                  ) in eventData.eventScheduleSummary"
                                  :key="indexType"
                                >
                                  <div class="text-center">
                                    <h5>
                                      {{
                                        moment(itemType.event_sch_date).format(
                                          "DD/MM/YYYY"
                                        )
                                      }}
                                      -
                                      {{ itemType.event_sch_time }}
                                    </h5>

                                    <table class="mt-1" style="width: 100%">
                                      <tr>
                                        <td>Seat Type</td>
                                        <td>
                                          Total Seats
                                          <b-badge pill>
                                            {{
                                              itemType.sch_max_capacity
                                            }}</b-badge
                                          >
                                        </td>
                                        <td>
                                          Sold
                                          <b-badge pill variant="success">
                                            {{
                                              itemType.sch_sold_seats
                                            }}</b-badge
                                          >
                                        </td>
                                        <td>
                                          Available
                                          <b-badge pill variant="danger">
                                            {{
                                              parseFloat(
                                                itemType.sch_max_capacity
                                              ) -
                                              parseFloat(
                                                itemType.sch_sold_seats
                                              )
                                            }}</b-badge
                                          >
                                        </td>
                                      </tr>
                                      <tr
                                        v-for="(
                                          itemSeat, indexSeat
                                        ) in itemType.seatTypes"
                                        :key="indexSeat"
                                      >
                                        <td>{{ itemSeat.seat_type }}</td>
                                        <td>{{ itemSeat.total_allocated }}</td>
                                        <td>{{ itemSeat.count }}</td>
                                        <td>
                                          {{
                                            parseFloat(
                                              itemSeat.total_allocated
                                            ) - parseFloat(itemSeat.count)
                                          }}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </b-list-group-item>
                              </b-list-group>
                            </div>
                          </b-list-group>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </b-col>
          </b-row></b-card-text
        >
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BCardFooter,
  BCardImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BListGroup,
  BListGroupItem,
  BFormInput,
  BImg,
  BNav,
  BNavItem,
  BNavForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  GetOrganizationListService,
  GetEventListService,
  GetEventDataService,
} from "@/apiServices/MasterServices";
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BCardFooter,
    BCardImg,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BListGroup,
    BListGroupItem,
    BImg,

    BFormInput,

    BNav,
    BNavItem,
    BNavForm,
  },
  data() {
    return {
      moment: moment,
      org_name: sessionStorage.getItem("userOrg"),
      orgArray: [],
      itemsEventArray: [],
      eventData: null,
      selectedEventId: null,
      selectedEventType: "Y",
    };
  },

  directives: {
    Ripple,
  },

  beforeMount() {
    // this.getOrgList();
    this.getEventList(11, "Y");
  },

  methods: {
    async getOrgList() {
      try {
        const response = await GetOrganizationListService({
          limit: 100,
        });
        if (response.data.status) {
          this.orgArray = response.data.Records.data;
          if (this.orgArray.length) {
            this.getEventList(this.orgArray[0].org_id);
          }
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getEventList(org_id, selectedEventType) {
      try {
        this.selectedEventType = selectedEventType;
        this.isBusy = true;
        const response = await GetEventListService({
          limit: 1000,
          org_id: org_id,
          selectedEventType: selectedEventType,
        });
        if (response.data.status) {
          this.itemsEventArray = response.data.Records;
          // this.items = response.data.Records.data;
          // if (!isNaN(response.data.Records.pagination.total)) {
          //   this.pagination.totalRows = response.data.Records.pagination.total;
          // }
        } else {
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    async getEventDataById(eventId) {
      try {
        this.eventData = null;
        this.isBusy = true;
        console.log(eventId, "eventId");
        const response = await GetEventDataService({
          limit: 1000,
          event_id: eventId,
          selectedEventType: this.selectedEventType,
        });
        if (response.data.status) {
          this.eventData = response.data.Records;
        } else {
        }
        this.isBusy = false;
        this.selectedEventId = eventId;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 2px solid red;
  text-align: left;
  padding: 8px;
}
</style>
