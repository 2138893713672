var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button-group',[_c('b-button',{attrs:{"variant":_vm.selectedEventType == 'Y' ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.getEventList(11, 'Y')}}},[_vm._v("Active Events")]),_c('b-button',{attrs:{"variant":!_vm.selectedEventType ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.getEventList(11, '')}}},[_vm._v("All Events")])],1)],1)],1),_c('b-card',{staticClass:"mt-2",attrs:{"no-body":""}},[_c('b-card-text',[_c('b-alert',{attrs:{"variant":"success","show":""}},[_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"size":"sm","variant":"link"}},[_vm._v(" "+_vm._s(_vm.selectedEventType == "Y" ? "Total Active" : "All")+" Events "),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.itemsEventArray.length))])],1)],1)]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"m-1",staticStyle:{"border":"1px solid grey","border-radius":"5px","margin-right":"1 px"}},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},_vm._l((_vm.itemsEventArray),function(itemEvent,indexEvent){return _c('b-card',{key:indexEvent,staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{attrs:{"block":"","size":"sm","variant":_vm.selectedEventId == itemEvent.event_id
                          ? 'dark'
                          : 'secondary'},on:{"click":function($event){return _vm.getEventDataById(itemEvent.event_id)}}},[_c('b',[_vm._v(_vm._s(itemEvent.event_name)),_c('br'),_c('br'),_vm._v(" Date : "+_vm._s(_vm.moment(itemEvent.event_start_date).format( "DD/MM/YYYY" ))+" - "+_vm._s(_vm.moment(itemEvent.event_end_date).format( "DD/MM/YYYY" )))])])],1),_c('b-collapse',{attrs:{"id":"accordion-" + itemEvent.event_id,"visible":_vm.selectedEventId == itemEvent.event_id ? true : false,"accordion":"accordion-" + itemEvent.event_id,"role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('b-list-group',[_c('b-list-group-item',[_c('b',[_vm._v("Total Transaction : "+_vm._s(_vm.eventData && _vm.eventData.event_total_trans ? _vm.eventData.event_total_trans : 0)+" ")])]),_c('b-list-group-item',[_c('b',[_vm._v("Voucher Transaction : "+_vm._s(_vm.eventData && _vm.eventData.event_total_voucher_trans ? _vm.eventData.event_total_voucher_trans : 0)+" ")]),(
                                _vm.eventData &&
                                _vm.eventData.voucherSummaryArray &&
                                _vm.eventData.voucherSummaryArray.length
                              )?_c('table',{staticClass:"mt-1",staticStyle:{"width":"50%"}},[_c('tr',[_c('td',[_vm._v("Voucher Code")]),_c('td',[_vm._v("Seat Type")]),_c('td',[_vm._v("Count")])]),_vm._l((_vm.eventData.voucherSummaryArray),function(voc,indexVoc){return _c('tr',{key:indexVoc},[_c('td',[_vm._v(_vm._s(voc.VoucherCode))]),_c('td',[_vm._v(_vm._s(voc.SeatType))]),_c('td',[_vm._v(_vm._s(voc.Count))])])})],2):_vm._e()]),_c('b-list-group-item',[_c('b',[_vm._v("Total Seats Booked : "+_vm._s(_vm.eventData && _vm.eventData.event_total_bookedSeats ? _vm.eventData.event_total_bookedSeats : 0)+" ")])]),_c('b-list-group-item',[_c('b',[_vm._v("Total Seats Scanned : "+_vm._s(_vm.eventData && _vm.eventData.event_total_seats_scanned ? _vm.eventData.event_total_seats_scanned : 0)+" ")])]),_c('div',{staticClass:"mt-1"},[_c('h5',[_c('b',[_c('u',[_vm._v("Schedule Details")])])]),(
                                _vm.eventData &&
                                _vm.eventData.eventScheduleSummary &&
                                _vm.eventData.eventScheduleSummary.length
                              )?_c('b-list-group',{attrs:{"horizontal":"md"}},_vm._l((_vm.eventData.eventScheduleSummary),function(itemType,indexType){return _c('b-list-group-item',{key:indexType,attrs:{"variant":"secondary"}},[_c('div',{staticClass:"text-center"},[_c('h5',[_vm._v(" "+_vm._s(_vm.moment(itemType.event_sch_date).format( "DD/MM/YYYY" ))+" - "+_vm._s(itemType.event_sch_time)+" ")]),_c('table',{staticClass:"mt-1",staticStyle:{"width":"100%"}},[_c('tr',[_c('td',[_vm._v("Seat Type")]),_c('td',[_vm._v(" Total Seats "),_c('b-badge',{attrs:{"pill":""}},[_vm._v(" "+_vm._s(itemType.sch_max_capacity))])],1),_c('td',[_vm._v(" Sold "),_c('b-badge',{attrs:{"pill":"","variant":"success"}},[_vm._v(" "+_vm._s(itemType.sch_sold_seats))])],1),_c('td',[_vm._v(" Available "),_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(parseFloat( itemType.sch_max_capacity ) - parseFloat( itemType.sch_sold_seats )))])],1)]),_vm._l((itemType.seatTypes),function(itemSeat,indexSeat){return _c('tr',{key:indexSeat},[_c('td',[_vm._v(_vm._s(itemSeat.seat_type))]),_c('td',[_vm._v(_vm._s(itemSeat.total_allocated))]),_c('td',[_vm._v(_vm._s(itemSeat.count))]),_c('td',[_vm._v(" "+_vm._s(parseFloat( itemSeat.total_allocated ) - parseFloat(itemSeat.count))+" ")])])})],2)])])}),1):_vm._e()],1)],1)],1)],1)],1)],1)}),1)])])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }